import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { HomeTitle, MementoContainer } from "../../styles/home";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function AdviceHomeComponent() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const ui = useSelector((state) => state.ui);
  const renderAdvices = [0, 1, 2].map((item, index) => (
    <Grid
      item
      key={index}
      xs={12}
      sm={4}
      md={2}
      lg={1}
      xl={1}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Box
        key={index}
        sx={
          matches
            ? {
                mb: 10,
                width: "100%",
                height: "auto",
                padding: "5px",
                borderRadius: "16px",
                border: "1px solid",
              }
            : {
                width: "100%",
                height: "428px",
                padding: "5px",
                borderRadius: "16px",
                border: "1px solid",
                mb: 10,
                // width: "436px",
              }
        }
      >
        <CardActionArea onClick={() => console.log("first")}>
          <Box sx={{ pt: "12px", pl: "12px", pr: "12px" }}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              image={
                index === 0
                  ? "../../../assets/advence1.svg"
                  : index === 1
                  ? "../../../assets/advence2.svg" //"../../../assets/driver.svg"
                  : index === 2
                  ? "../../../assets/advence3.svg" //"../../../assets/call.svg"
                  : ""
              }
              // height={"181px"}
              // image={`../../../../assets/logo.svg`}
              title="Contemplative Reptile"
            />
          </Box>

          <CardContent>
            <Typography
              gutterBottom
              variant="h3"
              component="h2"
              sx={{
                fontSize: matches ? ui.fontSmall + "px" : ui.fontContent + "px",
                fontWeight: 700,
                // mb: "20px",
              }}
            >
              {index === 0
                ? "Анхаарах зүйлс:"
                : index === 1
                ? "Анхаарах зүйлс:"
                : index === 2
                ? "Анхаарах зүйлс:"
                : ""}
            </Typography>

            {index === 0 ? (
              <Stack direction={"column"} spacing={1}>
                <Typography
                  sx={{
                    fontSize: matches
                      ? ui.fontSmall + "px"
                      : ui.fontContent + "px",
                    fontWeight: 500,
                    textAlign: "justify",
                    //  textTransform: "lowercase",
                    color: "text.primary",
                  }}
                  component="p"
                >
                  1.Нийтийн тээврийн зорчигч нь үйлчилгээний хөлсийг заавал төлж
                  үйлчлүүлнэ.
                </Typography>
              </Stack>
            ) : index === 1 ? (
              <Stack direction={"column"} spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <Typography>1.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Нийтийн тээврийн хэрэгсэлд дарааллыг баримтлан бие биедээ
                    саад болохгүйгээр сууж, буух ба ахмад настан хөгжлийн
                    бэрхшээлтэй иргэд, хүүхэдтэй жирэмсэн хүмүүсийг хүндлэн
                    дугаарын эхэнд сууж, буухад туслана.
                  </Typography>
                </Stack>
              </Stack>
            ) : index === 2 ? (
              <Stack direction={"column"} spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <Typography>1.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Нийтийн тээврийн хэрэгслийн дотрох тоноглол, бусад
                    төхөөрөмж, зорчигчийн суудал, түшлэг бүрээс бариул зэрэгт
                    өөриймсөг хайр гамтай хандана.
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              ""
            )}
          </CardContent>
        </CardActionArea>
      </Box>
    </Grid>
  ));
  return (
    <Box
      sx={
        theme.palette.mode === "light"
          ? {
              backgroundImage: "url(../../../assets/advBackLight.svg)",
              backgroundPosition: "25% 75%",
              backgroundRepeat: "noRepeat ",
              backgroundSize: "cover",
            }
          : {
              // background: "#0D1B2A",
              backgroundRepeat: "noRepeat ",
              backgroundImage: "url(../../../assets/advBack.svg)",

              backgroundPosition: "25% 75%",

              backgroundSize: "cover",
            }
      }
    >
      <Container maxWidth={"xl"}>
        <Stack direction={"column"} alignItems={"center"}>
          <HomeTitle
            sx={{
              fontWeight: matches ? 600 : 700,
              fontSize: matches ? ui.fontContent + "px" : ui.fontTitle1 + "px",
              pt: 2,
            }}
          >
            ИРГЭДЭД ӨГӨХ ЗӨВЛӨМЖ
          </HomeTitle>
          {/* <HomeTitle
            sx={{
              fontWeight: matches ? 600 : 700,
              fontSize: matches ? ui.fontContent + "px" : ui.fontTitle1 + "px",
              color: "text.secondary",
              maxWidth: "884px",
            }}
          >
            Та нийтийн тээврээр үйлчлүүлж байх үедээ бэлгийн дарамтад өртсөн
            тохиолдолд дараах зөвлөмжүүдийг анхаараарай.
          </HomeTitle> */}
          {/* <HomeTitle
            sx={{
              fontWeight: 600,
              fontSize: matches ? ui.fontSmall + "px" : ui.fontContent + "px",
              color: "text.primary",
              // maxWidth: "884px",
            }}
          >
            Та нийтийн тээврээр үйлчлүүлж байх үедээ бэлгийн дарамтад өртсөн
            тохиолдолд дараах зөвлөмжүүдийг анхаараарай.
          </HomeTitle> */}

          <Grid
            item
            container
            spacing={{ xs: 2, md: 2, lg: 2 }}
            justifyContent={"space-between"}
            // sx={{ margin: "20px 4px 10px 4px" }}
            columns={{ xs: 12, sm: 8, md: 6, lg: 3.1 }}
          >
            {renderAdvices}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
