import { Close, CloseOutlined, Height, Link } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { setDialogOpen } from "../../../app/uiSlice";
import BusSearchComponent from "./busSearchComponent";
import FormsGrivanceComponent from "./formsGrivance";
import {
  feedbackStoreFun,
  setClearGiverance,
} from "../../../feutures/home/homeSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function AddGrivance({ open, onClose, item }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);
  const valueTypeList = useSelector((state) => state.home.valueTypeList);
  // const transportRouteListData = useSelector(
  //   (state) => state.home.transportRouteListData
  // );
  // const transportRouteStopData = useSelector(
  //   (state) => state.home.transportRouteStopData
  // );
  const routeList = useSelector((state) => state.home.routeList);
  const loading = useSelector((state) => state.home.loading);
  const stopName = useSelector((state) => state.home.stopName);
  const uploadedFile = useSelector((state) => state.home.uploadedFile);
  const busInfoData = useSelector((state) => state.home.busInfoData);
  const phoneNumber = useSelector((state) => state.home.phoneNumber);
  const note = useSelector((state) => state.home.note);
  //console.log({ ui });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(stopName);
  const { dialogOpen } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const submitFeedBack = () => {
    uploadedFile.length > 0 &&
      uploadedFile.length > 0 &&
      console.log(uploadedFile.length);
    if (
      stopName !== null &&
      routeList !== null &&
      uploadedFile.length > 0 &&
      valueTypeList !== null &&
      busInfoData !== null &&
      phoneNumber !== null
    ) {
      const formData = new FormData();
      formData.append("id", 0);
      formData.append("company_id", busInfoData.company_id);
      formData.append("source_id", 3);
      formData.append("route_id", routeList.id);
      formData.append("stop_id", stopName.stop_id);
      formData.append("vehicle_id", busInfoData.id);
      formData.append("type_id", valueTypeList.id);
      formData.append("note", note);
      formData.append("phone_number", phoneNumber);
      //  formData.append("attachment", uploadedFiles);
      if (uploadedFile.length > 0) {
        Array.from(uploadedFile).forEach((img) => {
          //  console.log(img);
          formData.append("attachment", img);
        });
      }
      dispatch(feedbackStoreFun(formData)).then((res) => {
        if (res.payload.status.code === 200) {
          toast.success(`Санал хүсэлт илгээсэнд баярлалаа`, {
            position: "top-right",
            autoClose: 5000,
          });
          onClose();
        } else {
          toast.error(`${res.payload.status.message}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
        //  console.log(res);
      });
      // dispatch(setVehicleData(null));
      //props.onClose();
    } else {
      toast.error(`Мэдээллээ гүйцэд оруулна уу!!!`, {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };
  return (
    <>
      <BootstrapDialog
        maxWidth="lg"
        //onClose={onClose}
        fullScreen={matches ? true : false}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") {
            return;
          } else {
            return onClose();
          }
          // // console.log(reason);
        }}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === "dark" ? " #0D1B2A" : "#fff",
            backgroundImage: "none",
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontSize: ui.fontTitle1 }}
          id="customized-dialog-title"
        >
          {item.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent dividers>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={1}>
              <img src="../../../../assets/numburCircle1.svg" alt="" />
              <Typography variant="h6" fontSize={ui.fontTitle1 + "px"}>
                Автобус сонгох
              </Typography>
            </Stack>
            <Typography fontSize={ui.fontContent + "px"} color="text.primary">
              Төрийн ба нутгийн удирдлагын байгууллага, албан тушаалтны үйл
              ажиллагааг сайжруулах, шинэчлэх талаар хүсэлт гаргахдаа та улсын
              дугаараар эсвэл гарааш дугаараар хайн тухайн автобусыг сонгоно уу.
            </Typography>
            <BusSearchComponent matches={matches} />

            <Stack direction={"row"} spacing={1}>
              <img src="../../../../assets/numburCircle2.svg" alt="" />
              <Typography variant="h6" fontSize={ui.fontTitle1 + "px"}>
                Мэдээлэл бөглөх
              </Typography>
            </Stack>
            <FormsGrivanceComponent
              fontSize={ui.fontTitle1}
              type={item.child}
              matches={matches}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <LoadingButton
            loading={loading}
            sx={{
              color: "#ffff",
              background: loading ? "#F3F5FA" : "#0B3599",
              borderRadius: "8px",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
              "&:hover": {
                background:
                  theme.palette.mode === "dark" ? "#0B3599" : "#0B3599",
                boxShadow: "none",
              },
            }}
            autoFocus
            onClick={() => {
              // dispatch(setClearGiverance());
              // onClose();
              submitFeedBack();
            }}
          >
            Илгээх
          </LoadingButton>
          <Button
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? " #25323F" : "#F3F5FA",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
            autoFocus
            onClick={() => {
              dispatch(setClearGiverance());
              onClose();
            }}
          >
            Хаах
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
