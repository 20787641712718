import { Routes, Route, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/system";

import { HomeView } from "./feutures/home/homeView";
import { useDispatch, useSelector } from "react-redux";

import BaseHelper from "./helper/BaseHelper";

import { PrivateRoute } from "./middleware/priviteRoot";
//import theme from "./styles/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "@mui/material";
import CryptoJS from "crypto-js";
import { ProfileView } from "./feutures/auth/profileView";
import {
  getDanUrl,
  getUserData,
  loginDanApprove,
  setExpire,
} from "./feutures/auth/userSlice";
import { Box, Paper, Stack } from "@mui/material";
import theme, { ColorModeContext, useMode } from "./styles/theme";
import AppDrawer from "./components/drawer";
import Footer from "./components/footer";
import useDialogModal from "./hooks/useDailogModal";
import AppBar from "./components/appBar";
import LoginDailog from "./components/dialog/login";
import AdviceComponent from "./components/home/advice";
import { useTheme } from "@emotion/react";
import HelpDailog from "./components/dialog/help";

function App() {
  // const [theme, colorMode] = useMode();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  //const snackbarRef = useRef(null);
  const [getToook] = useState(JSON.parse(localStorage.getItem("persist:root")));
  const [bytes, setBytes] = useState(null);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [theme, colorMode] = useMode();
  const myParam = searchParams.get("isLogin");
  const ui = useSelector((state) => state.ui);

  const [LoginDetialDialog, showLoginDialog, closeDialog] =
    useDialogModal(LoginDailog);
  const [HelpDetialDialog, showHelpDialog, closeHelpDialog] =
    useDialogModal(HelpDailog);
  useEffect(() => {
    if (myParam) {
      showLoginDialog();
    } else {
    }
  }, []);
  // const bytes = CryptoJS.AES.decrypt(
  //   JSON.parse(getToook.user),
  //   "my-secret-key"
  // );

  //console.log(str);

  //alert(searchParams.get("civil_data="));

  //dispatch(signUpDanVerify(searchParams.get("civil_data=")));

  // useEffect(() => {
  //   typeof window !== undefined &&
  //     window.document.addEventListener("contextmenu", (e) => {
  //       e.preventDefault();
  //     });
  // }, []);

  // document.onkeydown = function (e) {
  //   // console.log(e.key);
  //   if (e.key === "F12") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "I") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "C") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "J") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.key === "u") {
  //     return false;
  //   }
  // };
  // console.log(user.isExpired);
  useEffect(() => {
    if (
      searchParams.getAll("civil_data").length > 0 &&
      user.user === null &&
      !user.isLogged
    ) {
      // console.log(searchParams.getAll("civil_data")[0]);
      dispatch(loginDanApprove(searchParams.getAll("civil_data")[0]));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (user.token !== null) {
      dispatch(setExpire(BaseHelper.tokenCheck(token)));
      // console.log(GetToken());
    }
  }, [dispatch]);
  // useEffect(() => {
  //   if (GetToken() === null || BaseHelper.tokenCheck()) {
  //     dispatch(getGuestToken()).then((res) => (document.location = "/"));
  //   }
  // }, [GetRole, dispatch]);
  // useEffect(() => {
  //   if (GetToken() !== null && BaseHelper.tokenCheck()) {
  //     dispatch(setExpire(BaseHelper.tokenCheck()));
  //   }
  // }, [GetRole, dispatch]);
  useEffect(() => {
    if (user.user === null && user.isLogged) {
      dispatch(getUserData());
    }
  }, [user, dispatch]);
  useEffect(() => {
    if (user.user === null && !user.isLogged && user.danUrl === "") {
      const data =
        process.env.NODE_ENV === "development"
          ? { redirect_url: "http://localhost:3000" }
          : { redirect_url: "https://ptd.ub.gov.mn" };
      dispatch(getDanUrl(data));
    }
  }, [user, dispatch]);
  useEffect(() => {
    // console.log(searchParams);
    if (searchParams.getAll("civil_data").length > 0 && !user.isLogged) {
      // console.log(searchParams.get("civil_data"));
      dispatch(loginDanApprove(searchParams.get("civil_data")));
    }
  }, [searchParams, user, dispatch]);
  useEffect(() => {
    document.title = "Нийслэлийн нийтийн тээвэр";
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
    // document.images = "images/logo.png";
  }, []);
  //  console.log(user.isLogged);

  const SnackbarType = {
    success: "success",
    fail: "fail",
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Paper
          elevation={0}
          sx={
            theme.palette.mode === "light"
              ? {
                  height: `auto`,
                  borderRadius: 0,
                  backgroundColor: "#F8F9FA",
                  // paddingBottom: 12,
                }
              : {
                  height: `auto`,
                  borderRadius: 0,
                  backgroundColor: "#060D15",
                }
          }
        >
          <AppBar
            openLogin={showLoginDialog}
            closeLogin={closeDialog}
            openHelp={showHelpDialog}
            closeHelp={closeHelpDialog}
          />
          <Routes>
            <Route
              path="/"
              element={
                <HomeView
                  showLoginDialog={showLoginDialog}
                  closeDialog={closeDialog}
                />
              }
            />
            <Route path="/advince" element={<AdviceComponent />} />
            <Route element={<PrivateRoute isLogged={user} />}>
              <Route path="/profile" element={<ProfileView />} />
            </Route>

            {/* <Route element={<PrivateRoute isLogged={user} />}>
          <Route path="/mvis" element={<ProfileView />} />
        </Route> */}
          </Routes>

          <LoginDetialDialog />
          <HelpDetialDialog />
        </Paper>
        <Footer />

        <AppDrawer openHelp={showHelpDialog} closeHelp={closeHelpDialog} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
