// eslint-disable-next-line no-unused-vars
import { Box, Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HeadBackroundImage, HomeTitle } from "../../styles/home";
import AppBar from "../../components/appBar";
import Banner from "../../components/banner";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Memento } from "../../components/home/memento";
import ServicesList from "../../components/services";
import RouteSelect from "../../components/home/routeSelect";
import AdviceComponent from "../../components/home/advice";
import useDialogModal from "../../hooks/useDailogModal";
import LoginDailog from "../../components/dialog/login";
import Footer from "../../components/footer";
import AdviceHomeComponent from "../../components/home/adviceHome";

export const HomeView = ({ showLoginDialog, closeDialog }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const ui = useSelector((state) => state.ui);
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  //const myParam = searchParams.get("isLogin");

  // const [LoginDetialDialog, showLoginDialog, closeDialog] =
  //   useDialogModal(LoginDailog);
  // console.log(ui);

  // useEffect(() => {
  //   if (myParam) {
  //     showLoginDialog();
  //   } else {
  //   }
  // }, []);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      {" "}
      {/* <Box sx={{}}>
        <AppBar ui={ui} openLogin={showLoginDialog} closeLogin={closeDialog} />
      </Box> */}
      <HeadBackroundImage
        src={
          matches
            ? "../../../assets/backMobile.png"
            : "../../../assets/4030.png" //"../../../assets/bannerBack.png"
        }
      >
        <Banner
          theme={theme}
          openLogin={showLoginDialog}
          closeLogin={closeDialog}
        />
      </HeadBackroundImage>
      <Memento
        matches={matches}
        isDark={theme.palette.mode === "light" ? false : true}
        ui={ui}
      />
      <ServicesList ui={ui} loginDialog={showLoginDialog} />
      <RouteSelect matches={matches} ui={ui} theme={theme} />
      <Box
        sx={
          theme.palette.mode === "light"
            ? {
                backgroundImage: "url(../../../assets/advBackLight.svg)",
                backgroundPosition: "25% 75%",
                backgroundRepeat: "noRepeat ",
                backgroundSize: "cover",
              }
            : {
                // background: "#0D1B2A",
                backgroundRepeat: "noRepeat ",
                backgroundImage: "url(../../../assets/advBack.svg)",

                backgroundPosition: "25% 75%",

                backgroundSize: "cover",
              }
        }
      >
        <AdviceHomeComponent />
      </Box>
      {/* <LoginDetialDialog /> */}
    </>
  );
};
