import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import {
  AppBarContainer,
  AppBarHeader,
  LogoImage,
  MyList,
  MyMenuLink,
} from "../../styles/appbar";

import { Colors } from "../../styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  getDanUrl,
  getGuestToken,
  logout,
} from "../../feutures/auth/userSlice";
import { useEffect, useState } from "react";
import { lighten } from "polished";

import { useNavigate } from "react-router-dom";
import { BannerPageImage } from "../../styles/home";
import Actions from "./actions";

export default function AppbarDesktop({
  matches,
  ui,
  openLogin,
  closeLogin,
  openHelp,
  closeHelp,
}) {
  // Now, how do I use this on ListItemText???
  // const useStyles = makeStyles((theme) => ({
  //   redText: {
  //     "& span": {
  //       color: "text.primary",
  //       fontSize: `${ui.fontContent}px`,
  //     },
  //   },
  // }));
  //const classes = useStyles();
  //const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const user = useSelector((state) => state.user);
  //console.log(ui);
  // useEffect(() => {
  //   if (loginDanUrl) {
  //     const timeout = setTimeout(() => {
  //       // 👇️ redirects to an external URL
  //       window.location.replace(loginDanUrl);
  //     }, 1000);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [loginDanUrl]);
  const toProfile = () => {
    // console.log("sdfsdfsd");
    navigate("/profile");
  };

  // const loginDan = () => {
  //   setLogin(true);
  //   // dispatch(getGuestToken());
  //   if (user.danUrl !== "") {
  //     const timeout = setTimeout(() => {
  //       // 👇️ redirects to an external URL
  //       window.location.replace(user.danUrl);
  //       setLogin(false);
  //     }, 1000);
  //     return () => clearTimeout(timeout);
  //   }
  // };

  return (
    <Box
      sx={
        theme.palette.mode === "light"
          ? { background: "#F8F9FA", boxShadow: "0px 8px 16px 0px #0000001A" }
          : { background: "#0D1B2A" }
      }
    >
      <Container maxWidth={"xl"}>
        <AppBarContainer>
          <Box
            sx={{
              display: "flex",
              mb: 1,
              // width: "175px",
              // margin: 4,
              // justifyContent: "center",
              // alignItems: "center",
              // padding: "2px 8px",
            }}
          >
            {/* <LogoImage src="/assets/logo.png" /> */}
            <BannerPageImage
              sx={{ width: 58, height: 58, mt: 1, alignSelf: "center" }}
              src="/assets/logo.png"
              onClick={() => {
                navigate("/");
              }}
            />
            <Stack
              direction={"column"}
              sx={{ padding: "2px", flexGrow: 2, pl: 1 }}
            >
              <Typography
                variant="caption"
                sx={
                  {
                    fontSize: ui.fontContent + "px",
                    color: "text.secondary",
                    mt: 1,
                    mb: "-2px",
                    // fontSize: "7px",
                  }
                  //   color: Colors.primary,
                }
              >
                {" "}
                Нийслэлийн засаг даргын хэрэгжүүлэгч агентлаг
              </Typography>
              <AppBarHeader
                sx={{
                  fontSize: ui.fontTitle2 + "px",
                  fontWeight: 700,
                  color: "text.secondary",
                  padding: 0,
                  mb: -1,
                }}
              >
                {" "}
                НИЙТИЙН ТЭЭВРИЙН
              </AppBarHeader>
              <AppBarHeader
                sx={{
                  fontSize: ui.fontTitle2 + "px",
                  fontWeight: 700,
                  color: "text.secondary",
                  padding: 0,
                }}
              >
                {" "}
                БОДЛОГЫН ГАЗАР
              </AppBarHeader>
            </Stack>
          </Box>
          <Box display={"flex"} sx={{ alignSelf: "center" }}>
            <MyList type="row">
              <MyMenuLink
                to="/"
                isDark={theme.palette.mode === "light" ? false : true}
              >
                <ListItemButton>
                  <ListItemText
                    primary="Нүүр"
                    primaryTypographyProps={{ fontSize: `${ui.fontContent}px` }}
                  />
                </ListItemButton>
              </MyMenuLink>

              <MyMenuLink
                to="/advince"
                isDark={theme.palette.mode === "light" ? false : true}
              >
                <ListItemButton>
                  <ListItemText
                    primary="Зөвлөмж"
                    primaryTypographyProps={{ fontSize: `${ui.fontContent}px` }}
                  />
                </ListItemButton>
              </MyMenuLink>
              <MyMenuLink
                to="/"
                isDark={theme.palette.mode === "light" ? false : true}
              >
                <ListItemButton>
                  <ListItemText
                    // sx={{ color: "text.primary" }}
                    primary="Холбоо барих"
                    primaryTypographyProps={{
                      fontSize: `${ui.fontContent}px`,
                    }}
                    onClick={() => dispatch(logout())}
                  />
                </ListItemButton>
              </MyMenuLink>
              <Divider orientation="vertical" flexItem />
              <MyMenuLink
                to={user.isLogged ? "/profile" : "/"}
                primaryTypographyProps={{ fontSize: `${ui.fontContent}px` }}
                isDark={theme.palette.mode === "light" ? false : true}
                // sx={
                //   login
                //     ? {
                //         background: lighten(0.05, "#FFDF0D4D"),

                //         border: `1.79px solid ${Colors.grey}`,
                //         borderColor: Colors.grey,
                //         borderRadius: "46px",
                //         "&:hover": {
                //           background: lighten(0.05, "#FFDF0D4D"),
                //           color: "text.primary",
                //           border: `1.79px solid ${Colors.grey}`,
                //           borderColor: Colors.grey,
                //           borderRadius: "46px",
                //         },
                //       }
                //     : user.isLogged
                //     ? {
                //         background: lighten(0.05, "#FFDF0D4D"),

                //         border: `1.79px solid ${Colors.secondary}`,
                //         borderColor: Colors.secondary,
                //         borderRadius: "46px",
                //       }
                //     : {}
                // }
              >
                <Box
                  sx={{
                    // margin: theme.spacing(1),
                    position: "relative",
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      if (user.isLogged) {
                        toProfile();
                      } else {
                        openLogin();
                        //loginDan();
                      }
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: `${ui.fontContent}px`,
                      }}
                      primary={
                        user.isLogged && user.user !== null
                          ? user.user.lastname.substring(0, 1) +
                            "." +
                            user.user.firstname
                          : "Нэвтрэх"
                      }
                    />
                  </ListItemButton>
                  {login ? (
                    <CircularProgress
                      size={25}
                      sx={{
                        // color: Colors.secondary,
                        position: "absolute",
                        top: "35%",
                        left: "40%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </MyMenuLink>
              <Divider orientation="vertical" flexItem />
              {/* <ListItemButton>
          <ListItemIcon onClick={() => setShowSearchBox(true)}>
            <Search />
          </ListItemIcon>
        </ListItemButton> */}
            </MyList>
            <Actions
              openHelp={openHelp}
              closeHelp={closeHelp}
              matches={matches}
            />
          </Box>

          {/* <Actions matches={matches} /> */}
        </AppBarContainer>
      </Container>
    </Box>
  );
}
