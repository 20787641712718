import { Close, CloseOutlined, Height, Link, Login } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { toast } from "react-toastify";
import { useState } from "react";
import ReactPlayer from "react-player";
const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function HelpDailog({ open, onClose }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);

  //console.log({ ui });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const danUrl = useSelector((state) => state.user.danUrl);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <BootstrapDialog
        // onClose={(event, reason) => {
        //   if (reason && reason === "backdropClick") {
        //     return;
        //   } else {
        //     return onClose();
        //   }
        //   // // console.log(reason);
        // }}

        maxWidth="lg"
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === "dark" ? " #0D1B2A" : "#fff",
            backgroundImage: "none",
            borderRadius: "16px",
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: ui.fontTitle1 }}>
          Видео заавар
        </DialogTitle>{" "}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent dividers>
          {" "}
          <Box>
            <ReactPlayer
              style={{
                // border: "1px solid",
                //borderColor: "grey",
                background: "black",
              }}
              light={true}
              controls={true}
              className="react-player"
              url={
                matches
                  ? "../../../video/userMobile.mp4"
                  : "../../../video/userWeb.mp4"
              }
              width="100%"
              height="100%"
              //width="100%"
              // height="500px"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          {/* <Button
            sx={{
              color: "#ffff",
              background: "#0B3599",
              borderRadius: "8px",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
              "&:hover": {
                background:
                  theme.palette.mode === "dark" ? "#0B3599" : "#0B3599",
                boxShadow: "none",
              },
            }}
            autoFocus
            onClick={() => {
              // dispatch(setClearGiverance());
              // onClose();
              //submitFeedBack();
            }}
          >
            Илгээх
          </Button> */}
          <Button
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? " #25323F" : "#F3F5FA",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
            autoFocus
            onClick={() => {
              // dispatch(setClearGiverance());
              onClose();
            }}
          >
            Хаах
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
