import { Menu, Search } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useUIContext } from "../../context/ui";
import { AppBarContainer, AppBarHeader } from "../../styles/appbar";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpen } from "../../app/uiSlice";
import { BannerPageImage } from "../../styles/home";
import { useNavigate } from "react-router-dom";

export default function AppbarMobile({ matches, ui, openHelp, closeHelp }) {
  const { drawerOpen } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { homeTitleFontSize, customTitleFontSize, homeDescriptionFontSize } =
  //   useUIContext();
  return (
    <AppBarContainer>
      <AppBarHeader textAlign={"start"} variant="h4">
        <Stack direction={"column"} sx={{ padding: "2px", flexGrow: 2, pl: 1 }}>
          <Box
            sx={{
              display: "flex",
              //mb: 1,
              // width: "175px",
              // margin: 4,
              // justifyContent: "center",
              // alignItems: "center",
              // padding: "2px 8px",
            }}
          >
            {/* <LogoImage src="/assets/logo.png" /> */}
            <BannerPageImage
              onClick={() => {
                navigate("/");
              }}
              sx={{ mr: 1 }}
              // sx={{ width: 58, height: 58, mt: 1, alignSelf: "center" }}
              src="/assets/logo.png"
            />
            <Stack
              direction={"column"}
              justifyContent={"center"}
              sx={{ padding: "2px", flexGrow: 2, pl: 0 }}
            >
              <Typography
                variant="caption"
                sx={
                  {
                    fontSize: ui.fontSmall + "px",
                    color: "text.secondary",
                    mt: 1,
                    mb: "-2px",
                    // fontSize: "7px",
                  }
                  //   color: Colors.primary,
                }
              >
                {" "}
                Нийслэлийн засаг даргын хэрэгжүүлэгч агентлаг
              </Typography>
              <AppBarHeader
                sx={{
                  fontSize: ui.fontContent + "px",
                  fontWeight: 600,
                  color: "text.secondary",
                  padding: 0,
                  mb: -1,
                }}
              >
                {" "}
                НИЙТИЙН ТЭЭВРИЙН
              </AppBarHeader>
              <AppBarHeader
                sx={{
                  fontSize: ui.fontContent + "px",
                  fontWeight: 600,
                  color: "text.secondary",
                  padding: 0,
                }}
              >
                {" "}
                БОДЛОГЫН ГАЗАР
              </AppBarHeader>
            </Stack>
          </Box>
        </Stack>
      </AppBarHeader>
      <IconButton
        onClick={() => dispatch(setDrawerOpen(true))}
        sx={{ alignSelf: "center" }}
      >
        <Menu />
      </IconButton>
      {/* <IconButton>
        <Search onClick={() => setShowSearchBox(true)} />
      </IconButton> */}
      {/* <Actions matches={matches} /> */}
    </AppBarContainer>
  );
}
