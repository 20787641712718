import React, { useEffect, useState } from "react";
import MapRoutes from "./mapRoutes";
import { HomeContainer, HomeTitle } from "../../styles/home";
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setRouteList,
  setStopName,
  setTransportRouteStopData,
  transportRouteListFun,
  transportRouteStopDataFun,
} from "../../feutures/home/homeSlice";

export default function RouteSelect({ ui, matches, theme }) {
  const [map, setMap] = useState(false);
  //  const [valueRouteList, setValueRouteList] = useState("");
  const [stopValue, setStopValue] = useState("");
  const [routeId, setRouteId] = useState("");
  // const [stopName, setStopName] = useState("");
  const transportRouteListData = useSelector(
    (state) => state.home.transportRouteListData
  );
  const routeList = useSelector((state) => state.home.routeList);
  const transportRouteStopData = useSelector(
    (state) => state.home.transportRouteStopData
  );
  const stopName = useSelector((state) => state.home.stopName);
  const dispatch = useDispatch();
  // console.log(transportRouteListData);
  useEffect(() => {
    if (transportRouteListData === null) {
      dispatch(transportRouteListFun());
    }
  }, [dispatch, transportRouteListData]);
  //console.log(transportRouteListData);
  const handleOnChangeText = (event, newValue) => {
    //  dispatch(setRouteList(newValue));
    // setValueRouteList(newValue);
    // setRouteId(newValue.id);
    // setStopName("");
    // setStopValue("");
    // dispatch(
    //   transportRouteStopDataFun({
    //     id: newValue.id,
    //   })
    // );
  };
  // console.log(transportRouteStopData);
  return (
    <Container maxWidth="xl">
      <HomeContainer
        sx={
          theme.palette.mode === "light"
            ? { background: "#fff", mb: 5 }
            : { background: "#0D1B2A" }
        }
      >
        <Stack direction={"column"} width={"100%"}>
          <HomeTitle
            sx={{ mt: 5, fontSize: ui.fontTitle1 + "px", fontWeight: 700 }}
          >
            Автобусны чиглэл, зогсоол
          </HomeTitle>
          {/* <HomeDescription
        sx={{ pl: 5, pr: 5, textAlign: "justify" }}
        variant="caption"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
      </HomeDescription> */}
          <Box
            sx={{
              p: 2,
            }}
          >
            <Stack
              direction={matches ? "column" : "row"}
              spacing={2}
              sx={{ width: "100%", p: 1 }}
            >
              <FormControl sx={{ m: 0, width: "100% " }}>
                {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}

                <Autocomplete
                  key={0}
                  autoComplete
                  size="small"
                  id="controllable-states-demo"
                  value={routeList}
                  // onSelectChange={handleOnChangeText}
                  onChange={(event, newValue) => {
                    dispatch(setRouteList(newValue));
                    dispatch(setTransportRouteStopData(newValue.stop));
                    dispatch(setStopName(""));
                    setMap(false);
                    // setValueRouteList(newValue);
                    //console.log({ newValue });
                    // setValueRouteList(newValue);
                    // setRouteId(newValue.id);
                    // setStopName("");
                    // setStopValue("");
                    // dispatch(
                    //   transportRouteStopDataFun({
                    //     id: newValue.id,
                    //   })
                    // );
                  }}
                  selectOnFocus
                  autoHighlight
                  clearOnBlur
                  handleHomeEndKeys
                  options={transportRouteListData}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    // console.log(option);
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  //   sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Чиглэл сонгох" />
                  )}
                />
              </FormControl>
              <FormControl sx={{ m: 0, width: "100% " }}>
                {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}

                <Autocomplete
                  key={1}
                  size="small"
                  value={stopName}
                  onChange={(event, newValue) => {
                    dispatch(setStopName(newValue));
                    setMap(true);
                    //  setRouteId(newValue.id);
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-esolo-with-text-demo"
                  options={
                    transportRouteStopData !== null
                      ? transportRouteStopData
                      : []
                  }
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    // console.log(option);
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  //   sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="  Зогсоол сонгох" />
                  )}
                />
              </FormControl>
              {/* <Button
            variant="text"
            onClick={() => setMap(true)}
            color="primary"
          >
            sdfd
          </Button> */}
            </Stack>
            {/* <GoogleMapView data={{}} /> */}
            {map ? (
              <MapRoutes
                name={stopName != null ? stopName.name : ""}
                lat={stopName != null ? stopName.lat : ""}
                lng={stopName != null ? stopName.lng : ""}
              />
            ) : (
              ""
              // <BingMapsReact
              //   bingMapsKey="1a2b3c4d5e6f7g8h9i0j"
              //   height="500px"
              //   mapOptions={{
              //     navigationBarMode: "square",
              //   }}
              //   width="500px"
              //   viewOptions={{
              //     center: { latitude: 42.360081, longitude: -71.058884 },
              //     mapTypeId: "grayscale",
              //   }}
              // />
            )}
            {/* <TreeDataWithGap /> */}
          </Box>
        </Stack>
      </HomeContainer>
    </Container>
  );
}
