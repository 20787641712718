import { Facebook, Instagram, Send, Twitter } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  List,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FooterTitle, Subscribetf } from "../../styles/footer";

import { ColorModeContext, Colors, tokens } from "../../styles/theme";
import { useContext } from "react";

import { useSelector } from "react-redux";
export default function Footer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const ui = useSelector((state) => state.ui);

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        color: "#fff",
        background: "#0D1B2A",

        // fontSize: { xs: "12px", md: "14px" },
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={6} lg={3} sm={6} xs={12}>
            <List>
              <ListItemText>
                <FooterTitle
                  sx={{
                    fontSize: matches
                      ? ui.fontContent + "px"
                      : ui.fontSmall + "px",
                  }}
                >
                  Хаяг
                </FooterTitle>
                <Typography
                  sx={{
                    fontSize: matches
                      ? ui.fontSmall + "px"
                      : ui.fontContent + "px",
                  }}
                >
                  Улаанбаатар хот, Хан-Уул дүүрэг, 23 дугаар хороо, Нийслэлийн
                  нутгийн захиргааны цогцолбор “Б” блок 4 давхарт
                </Typography>
              </ListItemText>
            </List>
          </Grid>
          <Grid item md={6} lg={2} sm={6} xs={12}>
            <List>
              <ListItemText>
                <FooterTitle
                  sx={{
                    fontSize: matches
                      ? ui.fontContent + "px"
                      : ui.fontSmall + "px",
                  }}
                >
                  Facebook хуудас:
                </FooterTitle>

                <Link
                  href="https://www.facebook.com/transport.ub.gov.mn"
                  underline="hover"
                  target="_blank"
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                    }}
                  >
                    {" "}
                    Нийслэлийн Тээврийн Бодлогын Газар{" "}
                  </Typography>
                </Link>
              </ListItemText>
            </List>
          </Grid>
          <Grid item md={6} lg={2} sm={6} xs={12}>
            <List>
              <ListItemText>
                <FooterTitle
                  sx={{
                    fontSize: matches
                      ? ui.fontContent + "px"
                      : ui.fontSmall + "px",
                  }}
                >
                  Facebook групп:
                </FooterTitle>
                <Link
                  href="https://www.facebook.com/groups/689113287913136"
                  underline="hover"
                  target="_blank"
                >
                  {" "}
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                    }}
                  >
                    {" "}
                    Нийслэлийн Тээвэр - Public transport UB
                  </Typography>
                </Link>
              </ListItemText>
            </List>
          </Grid>
          <Grid item md={6} sm={6} lg={2}>
            <List>
              <ListItemText>
                <FooterTitle
                  sx={{
                    fontSize: matches
                      ? ui.fontContent + "px"
                      : ui.fontSmall + "px",
                  }}
                >
                  Утасны дугаар:
                </FooterTitle>
                <FooterTitle
                  sx={{
                    fontSize: matches
                      ? ui.fontContent + "px"
                      : ui.fontSmall + "px",
                  }}
                >
                  7004 4040
                </FooterTitle>
              </ListItemText>
            </List>
          </Grid>
          <Grid item md={6} sm={6} lg={2} xs={12}>
            <FooterTitle
              sx={{
                fontSize: matches ? ui.fontContent + "px" : ui.fontSmall + "px",
              }}
            >
              developed by:
            </FooterTitle>
            <Link
              href="https://www.infocon.mn/"
              underline="hover"
              target="_blank"
            >
              <img src="../../../assets/infocon.png" alt="" />
            </Link>
          </Grid>
          {/* <Grid
            container
            lg={9}
            md={5}
            // alignItems={"end"}
            // justifyContent={"end"}
          >
            <Grid item md={3} sm={3} lg={3}>
              <Link
                href="https://www.infocon.mn/"
                underline="hover"
                target="_blank"
              >
              
              </Link>
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
              <Link
                href="https://www.infocon.mn/"
                underline="hover"
                target="_blank"
              >
                <img
                  src="../../../assets/adb.png"
                  alt=""
                  height={42}
                  style={{
                    background: "#fff",
                    // paddingLeft: "9px",
                    paddingRight: "9px",
                  }}
                />
              </Link>
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
              <Link
                href="https://www.infocon.mn/"
                underline="hover"
                target="_blank"
              >
                <img src="../../../assets/korea.png" alt="" height={42} />
              </Link>
            </Grid>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}
